$(window).on('load', function(){
	$('.p-works-cat__item').each(function(){
		var wrap = $(this);
		var list = wrap.children('ul');
		var btn = wrap.children('div');		
		
		var windowWidth = window.innerWidth;
		var spOffset = 26;
		var wrapHeight = wrap.outerHeight();
		var listHeight = list.outerHeight();
		if(listHeight > wrapHeight) {
			btn.addClass('is-active');
			if(windowWidth > 767){
				if(wrap.hasClass('is-defaultOpen')) {
					wrap.css('height',listHeight);
					wrap.addClass('is-open');
				}
			}
		}
	
		btn.children('button').on('click', function(){
			if(!wrap.hasClass('is-open')){
				if(windowWidth > 767){
					wrap.css('height',listHeight);
				} else {
					wrap.css('height',listHeight+spOffset);
				}
				wrap.addClass('is-open');
			} else {
				wrap.css('height',wrapHeight);
				wrap.removeClass('is-open');
			}
		});
	});
});