/* ready */
$(function () {
  'use strict';

  animation($('.c-animate, .c-fade--up'));
});


/* scroll */
$(window).on('scroll', function () {
  animation($('.c-animate, .c-fade--up'));
});


/* function */
function animation(elm) {
  elm.each(function () {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).addClass('is-animate');
    }
  });
}