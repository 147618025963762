/* ready */
$(function () {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function () {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  // slider
  $('.c-list--works--slide').slick({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          centerPadding: "15%"
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ]
  });

  // gnaviCurrent
  gnaviCurrent($('.l-gnavi ul li'));

  // naviCurrent
  naviCurrent($('.c-list--link li, .c-list--service li'));

  // gnavi active
  $('.l-gnavi > ul > li').on({
    'mouseenter': function () {
      $('.l-header').addClass('is-active');
    },
    'mouseleave': function () {
      $('.l-header').removeClass('is-active');
    }
  });

  // megamenu
  $('.l-mega__par > li').on('mouseenter', function () {
    let index = $(this).parent().children('li').index(this);

    $(this).addClass('is-select').siblings().removeClass('is-select');
    $(this).closest('.l-mega').find('.l-mega__chd > ul').removeClass('is-select');
    $(this).closest('.l-mega').find('.l-mega__chd > ul').eq(index).addClass('is-select');
  });

  // spnavi
  $('.l-header__menu').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-spnavi').stop().fadeToggle();
  });
  $('.l-spnavi nav > ul li span').on('click', function () {
    let data_navi = $(this).parent().attr('data-navi');

    $(this).parent().addClass('is-select').siblings().removeClass('is-select');
    $('.l-spnavi__gt').find('div[data-navi=' + data_navi + ']').addClass('is-select').siblings().removeClass('is-select');
  });

  // fnaviAccordion
  $('.l-footer__nav__acc-ttl span').on('click', function () {
    if ($(this).parent().hasClass('l-footer__nav__acc-ttl--service')) {
      $(this).parent().toggleClass('is-open').next('ul').stop().slideToggle();
      $(this).closest('div').next().find('ul').stop().slideToggle();
    } else {
      $(this).parent().toggleClass('is-open').next('ul').stop().slideToggle();
    }
  });

  // accordion
  accordion($('.c-list--faq dt'));
});


/* scroll */
$(window).on('scroll', function () {
  const header = $('.l-header');
  const pagetopBtn = $('.l-pagetop');
  const footerBtn = $('.l-footer__consultation');

  if ($(this).scrollTop() > 500) {
    header.addClass('is-fixed');
    pagetopBtn.fadeIn();
    footerBtn.fadeIn();
  } else {
    header.removeClass('is-fixed');
    pagetopBtn.fadeOut();
    footerBtn.fadeOut();
  }
});


function gnaviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    if (link == '/') {
      var matchLink = new RegExp('^' + link + '$');
    } else {
      var matchLink = new RegExp('^' + link);
    }

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    var matchLink = new RegExp('^' + link + '$');

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

function accordion(accElm) {
  $(accElm).on('click', function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });
}